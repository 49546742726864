import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';

import { translation } from './translate-public';
import LoginPage from './components/login.jsx';

import 'bootstrap3/less/bootstrap.less';
import './styles/login.scss';

function renderRoot() {
  return <LoginPage isMiniVersion={false} />;
}

async function start() {
  await translation.initLanguage(translation.fallbackLanguage);

  ReactDOM.render(renderRoot(), document.getElementById('app'));
}

start();
